@use 'sass:map';
@use '@angular/material' as mat;

@mixin asc-notification-bar-theme($theme) {
   $warn: map.get($theme, warn);

   asc-notification-bar {
      background-color: mat.m2-get-color-from-palette($warn, 500);
      color: mat.m2-get-color-from-palette($warn, '500-contrast');
   }
 }
