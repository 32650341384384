$break-point-xs-width: 600px;
$break-point-sm-width: 960px;
$break-point-md-width: 1280px;

$media-query-xs: 'screen and (max-width: #{$break-point-xs-width - 1})';
$media-query-sm: 'screen and (min-width: #{$break-point-xs-width}) and (max-width: #{$break-point-sm-width - 1})';
$media-query-md: 'screen and (min-width: #{$break-point-sm-width}) and (max-width: #{$break-point-md-width - 1})';

$media-query-gt-xs: 'screen and (min-width: #{$break-point-xs-width})';
$media-query-gt-sm: 'screen and (min-width: #{$break-point-sm-width})';
$media-query-gt-md: 'screen and (min-width: #{$break-point-md-width})';

$media-query-lt-sm: 'screen and (max-width: #{$break-point-xs-width - 1})';
$media-query-lt-md: 'screen and (max-width: #{$break-point-sm-width - 1})';
$media-query-lt-lg: 'screen and (max-width: #{$break-point-md-width - 1})';

$null: null;

$display-flex: flex;
$display-inline-flex: inline-flex;

$direction-row: row;
$direction-row-reverse: row-reverse;
$direction-column: column;
$direction-column-reverse: column-reverse;

$align-start: flex-start;
$align-end: flex-end;
$align-center: center;
$align-stretch: stretch;
$align-horizontal-space-between: space-between;
$align-horizontal-space-around: space-around;
$align-horizontal-space-evenly: space-evenly;

$gap-width: 8px;
$gap1: 1 * $gap-width;
$gap2: 2 * $gap-width;
$gap3: 3 * $gap-width;
$gap4: 4 * $gap-width;
$gap5: 5 * $gap-width;



@mixin flex-container($display: $null, $direction: $null, $align-horizontal: $null, $align-vertical: $null, $gap: $null) {

    // Default values
    @if ($direction==$null) {
        $direction: $direction-row;
    }

    @if ($display==$null and ($direction !=$null or $align-horizontal !=$null or $align-vertical !=$null or $gap !=$null)) {
        $display: $display-flex;
    }

    // Flex display
    display: $display;

    // Flex direction
    @if ($direction !=$null) {
        flex-direction: $direction;
    }

    // Flex Alignments
    @if ($direction==$direction-row or $direction==$direction-row-reverse) {
        @if ($align-horizontal !=$null) {
            justify-content: $align-horizontal;
        }

        @if ($align-vertical !=$null) {
            align-items: $align-vertical;
        }
    }

    @else if ($direction==$direction-column or $direction==$direction-column-reverse) {
        @if ($align-horizontal !=$null) {
            align-items: $align-horizontal;
        }

        @if ($align-vertical !=$null) {
            justify-content: $align-vertical;
        }
    }

    // Flex gaps
    @if($gap !=$null) {
        gap: $gap;
    }

    // @if ($direction==$direction-row) {
    //     &>*:not(:last-child) {
    //         margin-right: $gap;
    //     }
    // }

    // @else if ($direction==$direction-row-reverse) {
    //     &>*:not(:last-child) {
    //         margin-left: $gap;
    //     }
    // }

    // @else if ($direction==$direction-column) {
    //     &>*:not(:last-child) {
    //         margin-bottom: $gap;
    //     }
    // }

    // @else if ($direction==$direction-column-reverse) {
    //     &>*:not(:last-child) {
    //         margin-top: $gap;
    //     }
    // }
}

@mixin flex($flex: $null) {

    // Default values
    @if ($flex ==$null) {
        flex: 1 1 1e-9px;
    }

    @else {
        flex: $flex;
        min-width: $flex;
        max-width: $flex;
    }

}

@mixin order($order) {
    order: $order;
}