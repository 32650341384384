@use 'sass:map';
@use '@angular/material' as mat;

@mixin asc-html-editor-theme($theme) {
   $accent: map.get($theme, accent);


   .ql-snow.ql-toolbar button:hover,
   .ql-snow .ql-toolbar button:hover,
   .ql-snow.ql-toolbar button:focus,
   .ql-snow .ql-toolbar button:focus,
   .ql-snow.ql-toolbar button.ql-active,
   .ql-snow .ql-toolbar button.ql-active,
   .ql-snow.ql-toolbar .ql-picker-label:hover,
   .ql-snow .ql-toolbar .ql-picker-label:hover,
   .ql-snow.ql-toolbar .ql-picker-label.ql-active,
   .ql-snow .ql-toolbar .ql-picker-label.ql-active,
   .ql-snow.ql-toolbar .ql-picker-item:hover,
   .ql-snow .ql-toolbar .ql-picker-item:hover,
   .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
   .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
      border-radius: 2px;
      background-color: mat.m2-get-color-from-palette($accent, 500);
      color: mat.m2-get-color-from-palette($accent, A100);
   }

   .ql-snow.ql-toolbar button:hover .ql-stroke,
   .ql-snow .ql-toolbar button:hover .ql-stroke,
   .ql-snow.ql-toolbar button:focus .ql-stroke,
   .ql-snow .ql-toolbar button:focus .ql-stroke,
   .ql-snow.ql-toolbar button.ql-active .ql-stroke,
   .ql-snow .ql-toolbar button.ql-active .ql-stroke,
   .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
   .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
   .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
   .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
   .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
   .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
   .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
   .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
   .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
   .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
   .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
   .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
   .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
   .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
   .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
   .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
   .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
   .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
   .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
   .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
   .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
   .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
      stroke: currentColor;
   }

   .ql-snow.ql-toolbar button:hover .ql-fill,
   .ql-snow .ql-toolbar button:hover .ql-fill,
   .ql-snow.ql-toolbar button:focus .ql-fill,
   .ql-snow .ql-toolbar button:focus .ql-fill,
   .ql-snow.ql-toolbar button.ql-active .ql-fill,
   .ql-snow .ql-toolbar button.ql-active .ql-fill,
   .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
   .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
   .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
   .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
   .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
   .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
   .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
   .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
   .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
   .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
   .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
   .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
   .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
   .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
   .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
   .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
   .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
   .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
   .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
   .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
   .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
   .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
      fill: currentColor;
   }
}
