$gutter-width: 8px;
$side-panel-width: 300px;
$area-content-width: 600px;


@mixin view-content-vertical-spacing() {
   & > *:not(:last-child) {
      margin-bottom: (3 * $gutter-width);
   }
}
