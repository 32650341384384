@use 'sass:map';
@use '@angular/material' as mat;

@mixin asc-notification-header-theme($theme, $themeLight) {
   $primary: map.get($theme, primary);
   $primaryLight: map.get($themeLight, primary);
   $foreground: map.get($theme, foreground);

   .asc-recipients-header>small {
      color: mat.m2-get-color-from-palette($foreground, secondary-text);
   }
}
