@use "sass:math";
@use '/src/styles/layout' as layout;

asc-dashboard,
asc-settings,
asc-splash-screen {
   flex: 1 1 auto;
   display: flex;
   flex-direction: column;
}

// ----------------------------------------------------------- Components: menu

.asc-menu {
   height: 100%;

   .asc-menu-banner {
      height: layout.$side-panel-width * math.div(9, 16);
      min-height: layout.$side-panel-width * math.div(9, 16);
      padding: (2 * layout.$gutter-width);
      box-sizing: border-box;

      img {
         width: 100%;
      }
   }
}

.mat-mdc-menu-panel {
   button.asc-new-section {
       border-top-style: solid;
       border-top-width: 1px;
   }
}
