@use "/src/styles/flex-layout" as fx;


// ------------------------------------------------------------ Flexbox: Layout

.fx-layout-row,
.fx-layout-row-reverse {
   @include fx.flex-container(fx.$display-flex);

   &.fx-horizontal-left {
      justify-content: flex-start;
   }

   &.fx-horizontal-center {
      justify-content: center;
   }

   &.fx-horizontal-right {
      justify-content: flex-end;
   }

   &.fx-vertical-top {
      align-items: flex-start;
   }

   &.fx-vertical-center {
      align-items: center;
   }

   &.fx-vertical-bottom {
      align-items: flex-end;
   }
}

.fx-layout-row {
   flex-direction: row;
}

.fx-layout-row-reverse {
   flex-direction: row-reverse;
}


.fx-layout-column,
.fx-layout-column-reverse {
   display: flex;

   &.fx-horizontal-left {
      justify-content: flex-start;
   }

   &.fx-horizontal-center {
      justify-content: center;
   }

   &.fx-horizontal-right {
      justify-content: flex-end;
   }

   &.fx-vertical-top {
      align-items: flex-start;
   }

   &.fx-vertical-center {
      align-items: center;
   }

   &.fx-vertical-bottom {
      align-items: flex-end;
   }
}

.fx-layout-column {
   flex-direction: column;
}

.fx-layout-column-reverse {
   flex-direction: column-reverse;
}


// -------------------------------------------------------------- Flexbox: Flex

.fx-flex {
   @include fx.flex();
}

@media #{fx.$media-query-xs} {
   .fx-flex-xs {
      @include fx.flex();
   }   
}

@media #{fx.$media-query-sm} {
   .fx-flex-sm {
      @include fx.flex();
   }   
}

@media #{fx.$media-query-md} {
   .fx-flex-md {
      @include fx.flex();
   }   
}

@media #{fx.$media-query-gt-xs} {
   .fx-flex-gt-xs {
      @include fx.flex();
   }   
}

@media #{fx.$media-query-gt-sm} {
   .fx-flex-gt-sm {
      @include fx.flex();
   }   
}

@media #{fx.$media-query-gt-md} {
   .fx-flex-gt-md {
      @include fx.flex();
   }   
}


// ------------------------------------------------------------------ Flex hide

.fx-hide {
   display: none !important;
}

@media #{fx.$media-query-xs} {
   .fx-hide-xs {
      display: none !important;
   }   
}

@media #{fx.$media-query-sm} {
   .fx-hide-sm {
      display: none !important;
   }   
}

@media #{fx.$media-query-md} {
   .fx-hide-md {
      display: none !important;
   }   
}

@media #{fx.$media-query-gt-xs} {
   .fx-hide-gt-xs {
      display: none !important;
   }   
}

@media #{fx.$media-query-gt-sm} {
   .fx-hide-gt-sm {
      display: none !important;
   }   
}

@media #{fx.$media-query-gt-md} {
   .fx-hide-gt-md {
      display: none !important;
   }   
}

@media #{fx.$media-query-lt-sm} {
   .fx-hide-lt-sm {
      display: none !important;
   }   
}

@media #{fx.$media-query-lt-md} {
   .fx-hide-lt-md {
      display: none !important;
   }   
}