@use 'sass:map';
@use '@angular/material' as mat;

@mixin asc-menu-theme($theme, $themeLight) {
   $primary: map.get($theme, primary);
   $background: map.get($theme, background);

   .asc-menu {
      .mat-mdc-list-item {
         color: mat.m2-get-color-from-palette($primary, 500);

         .mat-icon,
         .mdc-list-item__secondary-text {
            color: inherit;
         }
      }
   }

   .asc-menu-banner {
      background-color: mat.m2-get-color-from-palette($background, card);
   }
}
