@use 'sass:map';
@use '@angular/material' as mat;

@mixin asc-notification-theme($theme, $themeLight) {
   $foreground: map.get($theme, foreground);

   .asc-notification-message-date-range {
      color: mat.m2-get-color-from-palette($foreground, secondary-text);
   }
}
