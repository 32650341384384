@use '/src/styles/flex-layout' as fx;
@use '/src/styles/layout';
@use "./styles.theme.scss";
@use 'quill/dist/quill.snow.css';


.asc-notification-message-body {

   &>h1,
   &>h2,
   &>h3,
   &>h4,
   &>h5,
   &>h6,
   &>p,
   &>ul,
   &>ol {
      padding-left: (2 * layout.$gutter-width);
      padding-right: (2 * layout.$gutter-width);
   }
}

.asc-notification-preview-dialog {
   .mat-mdc-dialog-container {
      padding: 0;
   }

   .mdc-dialog__surface {
      overflow-y: unset;
   }
}

.asc-notification-approvers-dialog {
   .mat-mdc-radio-button {
      display: flex;
      height: (7 * layout.$gutter-width);
   }
}

.asc-approvers-dropdown {
   .mat-mdc-option {
      @include fx.flex-container($direction: fx.$direction-row, $align-horizontal: fx.$align-stretch);

      & .mdc-list-item__primary-text {
         @include fx.flex();
         @include fx.flex-container($direction: fx.$direction-row, $align-vertical: fx.$align-center);
      }
   }

   .asc-notification-approver-icon {
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 50%;
      box-sizing: border-box;
      padding: 4px;
      overflow: clip;

      margin-right: (2 * layout.$gutter-width);
      height: (5 * layout.$gutter-width);
      width: (5 * layout.$gutter-width);

      img {
         height: 100%;
         width: 100%;
         object-fit: contain;
      }
   }
}

asc-admin-edit-notification {
   .asc-area-content {
      .mat-mdc-form-field-icon-suffix {
         align-self: flex-end;
         padding-bottom: layout.$gutter-width;
      }
   }
}

@media #{fx.$media-query-xs} {
   .asc-notification-message-template-dialog,
   .asc-notification-preview-dialog {
      height: 100vh;
      width: 100vw;
   }
}

@media #{fx.$media-query-gt-xs} {
   .asc-notification-message-template-dialog {
      height: 700px;
      width: 600px;
   }

   .asc-notification-preview-dialog {
      height: 700px;
      width: 1180px;
   }
}