@use 'sass:map';
@use '@angular/material' as mat;

@mixin asc-module-sus-password-reset-theme($theme, $themeLight) {
    $foreground: map.get($theme, foreground);

    .mat-expansion-panel-header-title {
        color: mat.m2-get-color-from-palette($foreground, secondary-text);
    }
}
