@use "sass:math";
@use '@angular/material' as mat;
@use './styles/flex-layout' as fx;
@use './styles/layout' as layout;
@use "./styles.layout.scss" as ascLayout;
@use "./styles.theme.scss" as ascTheme;
@use "./styles.typography.scss" as ascTypography;

// Module specific styles
@use "./modules/core/styles.scss" as ascCore;
@use "./modules/notifications/styles.scss" as ascNotifications;
@use "./modules/password-management/styles.scss" as ascPasswordManagement;
@use "./modules/sus-password-reset/styles.scss" as ascSusPasswordReset;

// ------------------------------------------------------------ Material Design

@media #{fx.$media-query-gt-xs} {
   .mdc-checkbox {
      .mat-mdc-checkbox-touch-target {
         height: (2.5 * layout.$gutter-width);
         width: (2.5 * layout.$gutter-width);
      }
   }
}

.mat-toolbar {
   .mat-divider-vertical {
      height: 100%;
   }
}

@media #{fx.$media-query-xs} {
   .mat-toolbar {
      min-height: (7 * layout.$gutter-width);
   }
}

// ----------------------------------------------------------------------------

html,
body {
   box-sizing: border-box;
   height: 100%;
   width: 100%;
   margin: 0;
   padding: 0;

   overscroll-behavior: contain; // Prevent reloading page on pan-down (mobile devices)
}

quill-editor {
   background-color: white;
   width: 100%;

   .ql-container {
      font-size: unset;
   }
}

.mat-mdc-menu-content .mat-mdc-menu-item.asc-context-menu-default-action .mat-mdc-menu-item-text {
   font-weight: bold;
}

.asc-form-field {
   padding-bottom: 20px;
}

.asc-form-field-label {
   display: inline-block;
   font-size: (0.75 * 16px);
   font-weight: 400;
   line-height: (math.div(9, 8) * 1em);

   &+* {
      padding-top: 0.4375em;
   }
}

.asc-footer {
   background-color: darkgrey;
   box-sizing: border-box;
   min-height: 20px;
   padding: 4px;
   z-index: 1;

   p {
      font-size: 11px;
      line-height: 11px;
      margin: 0px !important;
      color: #ffffff;
   }
}


// ---------------------------------------------------------------------- Areas

.asc-area-content {
   box-sizing: border-box;
   padding-top: (2 * layout.$gutter-width);
   padding-bottom: (2 * layout.$gutter-width);

   &:not(.fx-flex) {
      min-height: 100%;
   }

   p,
   ul,
   ol,
   .mat-mdc-form-field:not(.mat-toolbar .mat-mdc-form-field):not(asc-datetime-picker .mat-mdc-form-field),
   .asc-view-actions {
      box-sizing: border-box;
      padding-left: (2 * layout.$gutter-width);
      padding-right: (2 * layout.$gutter-width);
   }

   .ql-editor {
      padding: layout.$gutter-width;

      p,
      ul,
      ol,
      .mat-mdc-form-field:not(.mat-toolbar .mat-mdc-form-field):not(asc-datetime-picker .mat-mdc-form-field),
      .asc-view-actions {
         padding-left: 0;
         padding-right: 0;
      }
   }

   li {
      margin-left: (1.75 * layout.$gutter-width);
   }

   &.asc-toolbar-sticky-footer~.mat-toolbar {
      position: fixed;
      right: 0;
      width: auto;
      @include mat.elevation(3);
   }
}

.asc-area-content:not(.mat-toolbar-row) {
   @include layout.view-content-vertical-spacing();
}

router-outlet~* {
   flex: auto;
   position: relative;
}

@media #{fx.$media-query-xs} {

   .ql-snow.ql-toolbar button,
   .ql-snow .ql-toolbar button,
   .ql-snow.ql-toolbar .ql-picker,
   .ql-snow .ql-toolbar .ql-picker {
      padding: (1.5 * layout.$gutter-width);
      height: (6 * layout.$gutter-width);
      width: (6 * layout.$gutter-width);

      .ql-picker-label {
         padding: 0;
      }
   }

   .asc-area-content {
      width: 100vw;
   }

   .asc-footer {
      display: none;
   }

   .asc-area-content.asc-toolbar-sticky-footer {
      padding-bottom: (7 * layout.$gutter-width);

      &~.mat-toolbar {
         bottom: 0;
      }
   }
}

@media #{fx.$media-query-gt-xs} {

   .asc-area-content,
   .asc-area-content.mat-toolbar-row {
      margin-left: auto;
      margin-right: auto;
      width: layout.$area-content-width;
   }

   .asc-area-content.asc-toolbar-sticky-footer {
      padding-bottom: (10 * layout.$gutter-width);

      &~.mat-toolbar {
         bottom: 20px;
      }
   }
}

@media #{fx.$media-query-lt-lg} {
   .asc-area-content.asc-toolbar-sticky-footer {
      &~.mat-toolbar {
         left: 0;
      }
   }
}

@media #{fx.$media-query-gt-md} {
   .asc-area-content.asc-toolbar-sticky-footer {
      &~.mat-toolbar {
         left: layout.$side-panel-width;
      }
   }
}

// ------------------------------------------------------------ View components

h1.asc-view-module-header,
h2.asc-view-header:not(.mat-toolbar h2.asc-view-header) {
   padding-left: (2 * layout.$gutter-width);
   padding-right: (2 * layout.$gutter-width);
}


h1.asc-view-module-header {
   font-size: 14px;
   font-weight: normal;
   line-height: 14px;

   &,
   * {
      text-decoration: none;
   }

   .asc-view-module-header-separator::after {
      content: '►';
      font-size: 0.8em;
      margin: auto 0.5em;
   }
}
