@use 'sass:map';
@use '@angular/material' as mat;

@mixin asc-admin-edit-notification-theme($theme, $themeLight) {
   $primaryLight: map.get($themeLight, primary);
   $foreground: map.get($theme, foreground);

   .asc-message-status {
      color: mat.m2-get-color-from-palette($foreground, secondary-text);
   }

   .asc-recipients-panel,
   .asc-recipients-drawer {
      background-color: mat.m2-get-color-from-palette($primaryLight, 50);
   }
}
