@use 'sass:map';
@use '@angular/material' as mat;

@mixin asc-notification-list-theme($theme, $themeLight) {
   $primary: map.get($theme, primary);
   $primaryLight: map.get($themeLight, primary);
   $foreground: map.get($theme, foreground);

   .asc-notification-message-list-header,
   .asc-notification-message-list-item {
      border-bottom-color: mat.m2-get-color-from-palette($foreground, divider);
   }

   .asc-notification-message-info,
   .asc-recipients-header>small,
   .asc-recipient>small {
      color: mat.m2-get-color-from-palette($foreground, secondary-text);
   }

   .asc-notification-message-list-header {
      color: mat.m2-get-color-from-palette($primary, 500);
      background-color: mat.m2-get-color-from-palette($primaryLight, 50);
   }
}
