@use 'sass:map';
@use '@angular/material' as mat;

@use './modules/core/styles.theme.scss' as ascCore;
@use './modules/notifications/styles.theme.scss' as ascNotifications;
@use './modules/password-management/styles.theme.scss' as ascPasswordManagement;
@use './modules/sus-password-reset/styles.theme.scss' as ascSusPasswordReset;
@use './modules/user-profiles/styles.theme.scss' as ascUserProfiles;


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$mat-asc-primary: (
  50 : #e0e4e9,
  100 : #b3bcc7,
  200 : #8090a2,
  300 : #4d647c,
  400 : #264260,
  500 : #002144,
  600 : #001d3e,
  700 : #001835,
  800 : #00142d,
  900 : #000b1f,
  A100 : #5c80ff,
  A200 : #2958ff,
  A400 : #0036f5,
  A700 : #0031db,
  contrast: (50 : rgba(black, 0.87),
    100 : rgba(black, 0.87),
    200 : rgba(black, 0.87),
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : rgba(black, 0.87),
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$mat-asc-primary-light: (
  50 : #e7f7fa,
  100 : #c4ebf3,
  200 : #9dddeb,
  300 : #76cfe2,
  400 : #58c5dc,
  500 : #3bbbd6,
  600 : #35b5d1,
  700 : #2daccc,
  800 : #26a4c6,
  900 : #1996bc,
  A100 : #f0fbff,
  A200 : #bdeeff,
  A400 : #8ae1ff,
  A700 : #70dbff,
  contrast: (50 : rgba(black, 0.87),
    100 : rgba(black, 0.87),
    200 : rgba(black, 0.87),
    300 : rgba(black, 0.87),
    400 : rgba(black, 0.87),
    500 : rgba(black, 0.87),
    600 : rgba(black, 0.87),
    700 : rgba(black, 0.87),
    800 : rgba(black, 0.87),
    900 : #ffffff,
    A100 : rgba(black, 0.87),
    A200 : rgba(black, 0.87),
    A400 : rgba(black, 0.87),
    A700 : rgba(black, 0.87),
  )
);

$mat-asc-secondary: (
  50 : #fef4e0,
  100 : #fbe3b3,
  200 : #f9d180,
  300 : #f7bf4d,
  400 : #f5b126,
  500 : #f3a300,
  600 : #f19b00,
  700 : #ef9100,
  800 : #ed8800,
  900 : #ea7700,
  A100 : #ffffff,
  A200 : #ffedde,
  A400 : #ffd1ab,
  A700 : #ffc391,
  contrast: (50 : rgba(black, 0.87),
    100 : rgba(black, 0.87),
    200 : rgba(black, 0.87),
    300 : rgba(black, 0.87),
    400 : rgba(black, 0.87),
    500 : rgba(black, 0.87),
    600 : rgba(black, 0.87),
    700 : rgba(black, 0.87),
    800 : rgba(black, 0.87),
    900 : rgba(black, 0.87),
    A100 : rgba(black, 0.87),
    A200 : rgba(black, 0.87),
    A400 : rgba(black, 0.87),
    A700 : rgba(black, 0.87),
  )
);

// Create the theme object (a Sass map containing all of the palettes).
$asc-app-primary: mat.m2-define-palette($mat-asc-primary);
$asc-app-primary-light: mat.m2-define-palette($mat-asc-primary-light);
$asc-app-accent: mat.m2-define-palette($mat-asc-secondary);
$asc-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$asc-app-theme: mat.m2-define-light-theme((color: (primary: $asc-app-primary,
            accent: $asc-app-accent,
            warn: $asc-app-warn ),
         density: 0));

$asc-app-theme-light: mat.m2-define-light-theme((color: (primary: $asc-app-primary-light,
            accent: $asc-app-accent,
            warn: $asc-app-warn ),
         density: 0));

@include mat.all-component-themes($asc-app-theme);

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
}


// ------------------------------------------------------------ Theming modules
@include ascCore.asc-module-core-theme($asc-app-theme, $asc-app-theme-light);
@include ascNotifications.asc-module-notifications-theme($asc-app-theme, $asc-app-theme-light);
@include ascPasswordManagement.asc-module-password-management-theme($asc-app-theme, $asc-app-theme-light);
@include ascSusPasswordReset.asc-module-sus-password-reset-theme($asc-app-theme, $asc-app-theme-light);
@include ascUserProfiles.asc-module-user-profiles-theme($asc-app-theme, $asc-app-theme-light);


// ------------------------------------------------------ Theming global styles
$primary: map.get($asc-app-theme, primary);
$primary-light: map.get($asc-app-theme-light, primary);
$warning: map.get($asc-app-theme, accent);
$error: map.get($asc-app-theme, warn);
$foreground: map.get($asc-app-theme, foreground);
$background: map.get($asc-app-theme, background);
$label-color: mat.m2-get-color-from-palette($foreground, secondary-text);


.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: rgba(0, 0, 0, 0.02);
}

.mat-mdc-list-item.active-menu,
.mat-menu-item.active-menu {
  background-color: rgba(0,0,0, 0.07);

  .mat-icon,
  .mdc-list-item__secondary-text {
    color: inherit;
  }

  &:hover {
    background-color: rgba(0,0,0, 0.10);
  }
}



h1,
h2,
h3,
h4 {
   color: mat.m2-get-color-from-palette($primary, 500);
}

// ------------------------------------------------------------ View components

h1.asc-view-module-header {
   color: mat.m2-get-color-from-palette($primary-light, 500);

  a,
  a:visited {
    color: inherit
  }

   a:hover {
      color: mat.m2-get-color-from-palette($primary, 500);
   }
}


ul {
   li.asc-status {
      &>.mat-icon.asc-accepted {
         color: green;
         background-color: mat.m2-get-color-from-palette($background, card);
      }

      &>.mat-icon.asc-denied {
         color: mat.m2-get-color-from-palette($error);
         background-color: mat.m2-get-color-from-palette($background, card);
      }

      &>.mat-icon.asc-disabled {
         color: mat.m2-get-color-from-palette($foreground, 'disabled-button');
         background-color: mat.m2-get-color-from-palette($background, card);
      }

      &>.mat-icon.asc-warning {
         color: mat.m2-get-color-from-palette($warning, 500);
         background-color: mat.m2-get-color-from-palette($background, card);
      }
   }
}

.asc-form-field-label {
  color: $label-color;
}

.asc-error {
   color: mat.m2-get-color-from-palette($error);
}

.asc-warning {
   color: mat.m2-get-color-from-palette($warning, 900);
}

.asc-area-content {
   &:not(.mat-toolbar-row) {
      background-color: mat.m2-get-color-from-palette($background, card);
   }

  .mat-toolbar {
    background-color: unset;
  }
}

.asc-area-side-panel {
   background-color: mat.m2-get-color-from-palette($primary-light, 50);
}
