@use 'sass:map';
@use '@angular/material' as mat;
@use './components/menu/menu.component.theme.scss' as ascMenu;
@use './components/notification-bar/notification-bar.component.theme.scss' as ascNotificationsBar;
@use './components/html-editor/html-editor.component.theme.scss' as ascHtmlEditor;
@use './views/dashboard/dashboard.component.theme.scss' as ascDashboard;

@mixin asc-module-core-theme($theme, $themeLight) {
   @include ascMenu.asc-menu-theme($theme, $themeLight);
   @include ascDashboard.asc-dashboard-theme($theme, $themeLight);
   @include ascNotificationsBar.asc-notification-bar-theme($theme);
   @include ascHtmlEditor.asc-html-editor-theme($theme);

   $foreground: map.get($theme, foreground);
   $accent: map.get($theme, accent);

   .mat-mdc-menu-panel {
      button.asc-new-section {
         border-top-color: mat.m2-get-color-from-palette($foreground, divider);
      }
   }
}
