@use '@angular/material' as mat;


// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Tahoma, Arial, monospace',
  $headline-2: mat.m2-define-typography-level(48px, 56px, 600),
  $headline-5: mat.m2-define-typography-level(32px, 48px, 700),
  $body-2: mat.m2-define-typography-level(16px, 24px, 500)
);

.asc-area-content h2.asc-view-header {
  line-height: 56px;
  font-weight: 600;
}

// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
// @include mat-base-typography($custom-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.all-component-typographies($custom-typography);

.mat-typography h3 {
   margin: 0 0 12px;
}

.material-symbols-rounded {
   font-variation-settings:
      'FILL' 0,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24
}

.material-symbols-rounded-filled {
   font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24
}