@use 'sass:map';
@use '@angular/material' as mat;

@mixin asc-edit-theme($theme, $themeLight) {
   $primary: map.get($theme, primary);
   $primaryLight: map.get($themeLight, primary);
   $foreground: map.get($theme, foreground);

   asc-edit-user-profile {
      .asc-form {
         .asc-form-group {
            &:not(:first-child) {
               border-top-color: mat.m2-get-color-from-palette($foreground, divider);
            }
         }
      }
   }
}
